<template>
  <v-btn
    elevation="2"
    color="red"
    icon
    @click="evt => $emit('click', evt)"
  >
    <v-icon>
      mdi-cancel
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CustomCancelButton",
  props: {},
};
</script>

<style lang="scss" scoped>
</style>
