<template>
  <div class="exports-table">
    <v-simple-table>
      <thead>
        <tr class="exports-table__header">
          <th v-if="!forPreviousExports">
            File Name
          </th>
          <th>User</th>
          <th>Export Type</th>
          <th>Created On</th>
          <th v-if="!forPreviousExports">
            Completed On
          </th>
          <th># Rows</th>
          <th v-if="!forPreviousExports">
            Status
          </th>
          <th>{{ forPreviousExports ? 'Progress / Estimated Time Left' : '' }}</th>
          <th v-if="forPreviousExports" />
        </tr>
      </thead>
      <tbody class="exports-table__content">
        <template v-if="exports.length">
          <tr v-for="(item, i) in exports" :key="i">
            <td v-if="!forPreviousExports">
              {{ item.fileName }}
            </td>
            <td>
              <div>{{ getUserName(item) }}</div>
              <small v-if="item.user && item.user.email">{{ item.user.email }}</small>
            </td>
            <td>{{ getUpperCase(item.exportType) }}</td>
            <td>
              <div :title="getDateWithDayNameAndTime(item.createdAt)">
                {{ getDate(item.createdAt) }}
              </div>
            </td>
            <td v-if="!forPreviousExports" :title="getDateWithDayNameAndTime(item.updatedAt)">
              {{ getDateWithTime(item.updatedAt) }}
            </td>
            <td>{{ getExportedRowCount(item) }}</td>
            <td v-if="!forPreviousExports">
              <template v-if="item.status === 'failed'">
                <v-tooltip
                  v-if="item.exportData.failedException"
                  left
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      style="text-decoration: underline;color: #1976d2"
                      v-on="on"
                    >
                      {{ getUpperCase(item.status) }}
                    </span>
                  </template>
                  <span>{{ item.exportData.failedException }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                {{ getUpperCase(item.status) }}
              </template>
            </td>
            <td v-if="!forPreviousExports">
              <template v-if="item.amountOfRowsProcessed > 0 || (item.exportData.canBeEmpty && item.fileName)">
                <a
                  class="download-link"
                  target="_blank"
                  :href="getExportLink(item)"
                >
                  <v-icon size="16px" color="#2b84eb">
                    mdi-tray-arrow-down
                  </v-icon>
                  Download
                </a>
              </template>
            </td>
            <td
              v-else
              width="40%"
            >
              <template v-if="['queued', 'preparing', 'analyzing', 'requires action'].includes(item.status)">
                {{ getUpperCase(item.status) }}
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="9" style="margin-top: 5px;">
                    <v-progress-linear
                      color="#dee7ee"
                      :background-color="item.status === 'finalizing' ? null : '#dee7ee'"
                      height="10"
                      :value="item.percentage"
                      :indeterminate="item.status === 'finalizing'"
                    >
                      <div
                        v-if="item.status !== 'finalizing'"
                        class="percentage-display-box"
                        :style="(Math.ceil((item.amountOfRowsProcessed / item.totalRows) * 100) > 50 ? ('right: ' + (100 - Math.ceil((item.amountOfRowsProcessed / item.totalRows) * 100))) : ('left: ' + Math.ceil((item.amountOfRowsProcessed / item.totalRows) * 100))) + '%'"
                      >
                        <strong>{{ Math.ceil((item.amountOfRowsProcessed / item.totalRows) * 100) }}%</strong>
                      </div>
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="3">
                    <div v-if="item.status === 'finalizing'">
                      Finalizing
                    </div>
                    <div v-else-if="item.estimatedTimeLeft">
                      {{ item.estimatedTimeLeft }} left
                    </div>
                  </v-col>
                </v-row>
              </template>
            </td>
            <td v-if="forPreviousExports">
              <CustomCancelButton
                class="mt-1"
                @click="cancelDialog = true; cancelExportId = item.id;"
              />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="8" class="text-center">
              No results available.
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="cancelDialog"
      scrollable
      max-width="360px"
      @click:outside="cancelExportId = null"
    >
      <DeleteConfirmationDialog
        v-if="cancelDialog"
        header="Yes, Cancel Export"
        subheader="Do you really want to cancel this Export?"
        action_button_name="Cancel"
        cancel_button_name="Close"
        @delete="cancelExport(cancelExportId)"
        @dismiss="
          cancelDialog = false;
          cancelExportId = null;
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import CustomCancelButton from "@/sharedComponents/CustomCancelButton";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "ExportsTable",
  components: {
    CustomCancelButton,
    DeleteConfirmationDialog
  },
  mixins: [datesMixin],
  props: {
    exports: {
      default: () => [],
      type: Array,
    },
    forPreviousExports: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      failedReasonToShow: null,
      cancelDialog: false,
      cancelExportId: null,
    };
  },
  methods: {
    getUserName(exportItem) {
      // Messages sent by mercure with no user info are still sent as an empty array
      if (!exportItem.user || Array.isArray(exportItem.user)) {
        return 'Pulse Health - System'
      }

      return exportItem.user.firstName + ' ' + exportItem.user.lastName
    },
    getExportedRowCount(exportItem) {
      if (exportItem.status === 'canceled') {
        return exportItem.amountOfRowsProcessed.toLocaleString() + '/' + exportItem.totalRows.toLocaleString();
      }

      return exportItem.amountOfRowsProcessed.toLocaleString();
    },
    getUpperCase(str) {
      str = str.toLowerCase().split('_').join(' ');
      return (' ' + str).replace(/ (\w)/g, a => a.toLocaleUpperCase()).trim();
    },
    getExportLink(exportItem) {
      const accountId = this.$store.getters['user/account']?.accountId;
      if (!accountId) {
        return;
      }

      let apiUrl = process.env.VUE_APP_REST_ADDRESS;
      apiUrl = (apiUrl.substr(-1) === '/') ? apiUrl.slice(0, -1) : apiUrl;

      return apiUrl + '/exports/' + accountId + '/' + exportItem.id + '/download';
    },
    async cancelExport(exportId) {
      await this.$rest.exports.put_resource(exportId, {status: 'canceled'});

      this.cancelDialog = false;
      this.cancelExportId = null;
      this.$emit('canceled', exportId);
    },
  }
};
</script>

<style lang="scss" scoped>
.exports-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
    }
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }

  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  .percentage-display-box {
    position: absolute;
    padding: 2px 6px;
    border-radius: 2px;
    box-shadow: 0 1px 9px 0 rgba(43, 132, 235, 0.41);
    border: solid 1.5px #2b84eb;
    background-color: #fff;
    color: #2b84eb;
    transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  ::v-deep .v-progress-linear {
    // Allows percentage box to be seen
    overflow: visible;
  }
  ::v-deep .v-progress-linear__determinate {
    background-image: linear-gradient(to left, #2b84eb, #63abff);
  }
  ::v-deep .v-data-table {
    thead {
      tr {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09) !important;
      }
      tr > th {
        border-top: solid 1px #dee7ee;
        border-bottom: solid 1px #dee7ee !important;
      }
      tr > th:first-child {
        border-left: solid 1px #dee7ee;
        border-top-left-radius: 4px;
      }
      tr > th:last-child {
        border-right: solid 1px #dee7ee;
        border-top-right-radius: 4px;
      }
    }
    tbody {
      // table border radiuses
      tr:last-child > td:first-child {
        border-bottom-left-radius: 4px;
      }
      tr:last-child > td:last-child {
        border-bottom-right-radius: 4px;
      }
      // table border colors
      tr > td {
        border-bottom: solid 1px #dee7ee;
      }
      tr > td:first-child {
        border-left: solid 1px #dee7ee;
      }
      tr > td:last-child {
        border-right: solid 1px #dee7ee;
      }

      tr:hover {
        background: #f2faff !important;
      }
    }
  }
}

.download-link {
  color: #2b84eb !important;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>
